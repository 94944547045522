import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import AuthViews from "views/auth-views";
import Loading from "components/shared-components/Loading";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
export const AuthLayout = () => {
  const { status } = useThemeSwitcher();

  const data = [
    {
      title:
        "Opportunities increase when you help others win. A little win for our partner is a little win for us.",
    },
    {
      title: "Forget a Relationship, Build a Partnership and Build an Empire.",
    },
    {
      title: "A Partnership can make a HUGE difference. Help us to help you.",
    },
    {
      title: "Individually we are a drop in an ocean. Let's create the Ocean!",
    },
    {
      title:
        "Success is not the key to Happiness. Happiness is the key to success. If you love what you are doing, you will be successful!",
    },
  ];

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          overflow: "hidden",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.36)),url(/img/bg.jpg)",
          backgroundSize: "cover",
          objectFit: "fill",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            border: "1px solid #ffffff20",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <Row className="flex-column-reverse flex-md-row">
            <Col
              xs={24}
              md={12}
              style={{
                borderRight: "1px solid #2d2e2e",
                background: "white",
              }}
              // className="d-none d-md-flex"
            >
              <div className="p-3 pt-4 pl-4 d-flex flex-column ">
                <h1 style={{ fontSize: "24px" }}>
                  Telecoms <span className="text-primary">Supermarket</span> UK
                </h1>

                <p style={{ fontSize: "16px" }}>
                  Grow this platform and create problem solving statement.
                </p>

                {data.map((items) => {
                  return (
                    <>
                      <Flex alignItems="center" className="mb-2">
                        <CheckOutlined style={{ color: "green" }} />
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "6px",
                            marginBottom: 0,
                          }}
                        >
                          {items.title}
                        </p>
                      </Flex>
                    </>
                  );
                })}
              </div>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                background: "white",
              }}
            >
              <div className="w-100 p-3">
                <Switch>
                  <Route path="" component={AuthViews} />
                </Switch>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "40px",
          right: "40px",
        }}
        className="d-none d-md-flex"
      >
        <Link to="/terms-of-use">
          <p style={{ cursor: "pointer" }}>Term & Conditions</p>
        </Link>
        <p style={{ padding: "0px 20px" }}>|</p>
        <Link to="/privacy-policy">
          <p style={{ cursor: "pointer" }}>Privacy & Policy</p>
        </Link>
      </div>
    </>
  );
};

export default AuthLayout;
