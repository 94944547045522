import {
  GET_PLATFORM,
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
} from "../constants/User";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  user: "",
  platform_config: null,
};

const user = (state = initState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case GET_PLATFORM:
      return {
        ...state,
        loading: false,
        platform_config: action.config,
      };
    default:
      return state;
  }
};

export default user;
