import {
  AppstoreOutlined,
  DashboardOutlined,
  FundOutlined,
  UserSwitchOutlined, 
  SolutionOutlined,
  RadarChartOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined, 
  CreditCardOutlined,
  FolderOpenOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "Dashboard",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const aquasitionTree = [
  {
    key: "aqusition",
    path: `${APP_PREFIX_PATH}`,
    title: "Acquisition",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "aquastion-enquiry",
        path: `${APP_PREFIX_PATH}/enquiries/list`,
        title: "Enquiries",
        icon: SolutionOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "aquastion-enquiry-auction",
        path: `${APP_PREFIX_PATH}/auctions`,
        title: "Bids / Auctions",
        icon: RadarChartOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "aquastion-enquiry-purchased",
        path: `${APP_PREFIX_PATH}/enquiries-purchased`,
        title: "Purchased Enquiries",
        icon: DeploymentUnitOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const manageTree = [
  {
    key: "manage",
    path: `${APP_PREFIX_PATH}`,
    title: "Manage",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "business-listing",
        path: `${APP_PREFIX_PATH}/business-listing`,
        title: "Business Listing",
        icon: ClusterOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const transactionsTree = [
  {
    key: "profile",
    path: `${APP_PREFIX_PATH}`,
    title: "Profile",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "aquastion-wallet",
        path: `${APP_PREFIX_PATH}/wallet`,
        title: "Wallet & Transactions",
        icon: CreditCardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "main-user",
        path: `${APP_PREFIX_PATH}/profile`,
        title: "Profile",
        icon: UserSwitchOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "aquastion-tickets",
        path: `${APP_PREFIX_PATH}/tickets`,
        title: "Tickets",
        icon: FolderOpenOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const plansTree = [
  {
    key: "plans",
    path: `${APP_PREFIX_PATH}`,
    title: "Plans",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "plans",
        path: `${APP_PREFIX_PATH}/plans`,
        title: "Plans",
        icon: ApiOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...aquasitionTree,
  ...manageTree,
  ...transactionsTree,
  ...plansTree,
];

export default navigationConfig;
