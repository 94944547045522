import {
  EditOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import Icon from "components/util-components/Icon";
import React, { useState } from "react";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { useHistory, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { env } from "configs/EnvironmentConfig";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/app/profile",
  },
];

export const NavProfile = ({ signOut }) => {
  const history = useHistory();
  const handleSignout = () => {
    history.push("/auth/login");
    localStorage.removeItem("auth_token");
  };
  const user = useSelector((state) => state.user.user);

  const profileMenu = () => {
    return (
      <div className="nav-profile nav-dropdown">
        <div className="nav-profile-header">
          <div className="d-flex">
            <Avatar
              size={45}
              src={`${env.BASE_IMG_URL}/user/${user?.profile_picture}`}
            />
            <div className="pl-3">
              <h4 className="mb-0">
                {user.first_name ?? "N/A"} {user?.last_name ?? null}
              </h4>
              <span className="text-muted">
                {user?.partner_details?.position ?? "N/A"}
              </span>
            </div>
          </div>
        </div>
        <div className="nav-profile-body">
          <Menu>
            {menuItem.map((el, i) => {
              return (
                <Menu.Item key={i}>
                  <a href={el.path}>
                    <Icon className="mr-3" type={el.icon} />
                    <span className="font-weight-normal">{el.title}</span>
                  </a>
                </Menu.Item>
              );
            })}
            <Menu.Item
              key={menuItem.length + 1}
              onClick={(e) => handleSignout()}
            >
              <span>
                <LogoutOutlined className="mr-3" />
                <span className="font-weight-normal">Sign Out</span>
              </span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  };
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={`${env.BASE_IMG_URL}/user/${user.profile_picture}`} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
