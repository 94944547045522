import { GET_PLATFORM, GET_USER } from "../constants/User";

export const getUser = () => {
  return {
    type: GET_USER,
  };
};

export const setPlatformProperties = (config) => {
  return {
    type: GET_PLATFORM,
    config,
  };
};
