import { Grid } from "antd";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import utils from "utils";
import UserService from "../../services/User";
import { env } from "configs/EnvironmentConfig";
import AvatarStatus from "components/shared-components/AvatarStatus";
import { useSelector } from "react-redux";

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props) => {
  const { navCollapsed, logoType, partner_details } = props;
  if (navCollapsed) {
    return <span></span>;
  }
  return (
    <span>
      {partner_details?.logo && partner_details.company_name ? (
        <AvatarStatus
          name={partner_details?.company_name ?? partner_details.first_name}
          src={env.BASE_IMG_URL + "/partner/" + partner_details?.logo}
        />
      ) : (
        <>
          Telecoms <span className="text-primary">Supermarket</span>
        </>
      )}
    </span>
  );
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo text-nowrap";
  }
};

export const Logo = (props) => {
  const user = useSelector((state) => state.user.user);

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{
        width: `${getLogoWidthGutter(props, isMobile)}`,
        fontSize: "1.2rem",
        fontWeight: 500,
      }}
    >
      {getLogo({ ...user, ...props })}
      {/* <img src={getLogo(props)} alt={`${APP_NAME} logo`} /> */}
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
