import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards/sales`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/enquiries`}
          component={lazy(() => import(`./enquiries`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/enquiries-purchased`}
          component={lazy(() => import(`./enquiries-purchased`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/auctions`}
          component={lazy(() => import(`./auction_bid`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/wallet`}
          component={lazy(() => import(`./wallet`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./profile`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/payment`}
          component={lazy(() => import(`./Paypal`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/business-listing`}
          component={lazy(() => import(`./business-listing`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/plans`}
          component={lazy(() => import(`./plans`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/tickets`}
          component={lazy(() => import(`./tickets`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
