import fetch from "auth/FetchInterceptor";

const UserService = {};

UserService.platformProperties = function (params) {
  return fetch({
    url: "platform-properties",
    method: "get",
    params,
  });
};

UserService.get = function (id, params) {
  return fetch({
    url: "partner/profile",
    method: "get",
    params,
  })
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
};

UserService.post = function (data) {
  return fetch({
    url: "partner/profile",
    method: "post",
    // headers: {
    //   "content-type": "multipart/form-data",
    // },
    data: data,
  });
};

// UserService.put = function (id, data) {
//   return fetch({
//     url: "update-profile/" + id,
//     method: "post",
//     headers: {
//       "content-type": "multipart/form-data",
//     },
//     data: data,
//   });
// };

export default UserService;
