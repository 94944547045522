import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { GET_USER, GET_USER_FAIL, GET_USER_SUCCESS } from "../constants/User";
import UserService from "services/User";

export function* getUserData() {
  yield takeEvery(GET_USER, function* () {
    try {
      const { res, err } = yield call(UserService.get);
      yield put({ type: GET_USER_SUCCESS, data: res.data });
      if (err)
        yield put(
          yield put({ type: GET_USER_FAIL, message: err.response.data.message })
        );
    } catch (err) {
      yield put(
        yield put({ type: GET_USER_FAIL, message: err.response.data.message })
      );
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getUserData)]);
}
